var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"app"},[_c('loader-component',{attrs:{"showLoader":_vm.showLoader}}),_c('div',{staticClass:"view"},[(
        [
          'register',
          'login',
          'forget-password',
          'otp',
          'form-succeed',
          'reset-password',
          '',
        ].includes(_vm.$route.name)
      )?_c('v-container',{staticClass:"pt-8 pb-2"},[_c('router-link',{staticClass:"d-inline-flex",attrs:{"to":{ name: 'home' }}},[_c('img',{attrs:{"width":"140px","src":require("@/assets/images/logo-blue.svg")}})])],1):_vm._e(),(
        ![
          'register',
          'login',
          'forget-password',
          'otp',
          'form-succeed',
          'reset-password',
          '',
        ].includes(_vm.$route.name)
      )?_c('navbar-component'):_vm._e(),_c('v-container',{class:['home', 'privacy-policy'].includes(_vm.$route.name)
          ? 'wrapped-container'
          : 'no-wrapped-container',attrs:{"fluid":""}},[(_vm.isLoggedIn)?_c('sidebar-component'):_vm._e(),_c('router-view')],1),(!_vm.footerHiddenView.includes(_vm.$route.name))?_c('footer-component'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="services">
    <v-container class="py-0">
      <!-- Header Section -->
      <div class="text-center">
        <h4 class="platform-title">تجربة رقمية بسيطة ومتكاملة</h4>
        <h2 class="platform-heading">
          كل خدمات الشحن التي تحتاجها في مكان واحد
        </h2>
        <p class="platform-description">
          "وقت أقل، جهد أقل، وخدمات ذكية تغطي كل مراحل الشحن—هذا ما نقدمه لك في
          كاشيب."
        </p>
      </div>

      <!-- Card Section -->
      <v-row justify="center" class="features-row">
        <v-col
          v-for="(feature, index) in features"
          :key="index"
          cols="12"
          lg="4"
        >
          <v-card class="feature-card" outlined>
            <div class="feature-icon">
              <img :src="feature.icon" />
            </div>
            <h3 class="feature-title">{{ feature.title }}</h3>
            <p class="feature-description">{{ feature.description }}</p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "InnovativeSolutions",
  data() {
    return {
      features: [
         {
          icon: require("../../assets/images/landing-page/service1.svg"),
          title: "التخليص الجمركي",
          description:
            " إدارة وتنظيم عمليات التخليص الجمركي من خلال منصتنا الرقمية، مما يسرّع الإجراءات ويقلل من الوقت اللازم لإنهاء المستندات، مع تحديثات فورية حول حالة الشحنة.",
        },
        {
          icon: require("../../assets/images/landing-page/service2.svg"),
          title: "الحجز الفوري ",
          description:
            "خدمة حجز رقمية متكاملة تتيح لك اختيار الطريقة الأنسب لشحناتك في أي وقت وبسرعة، مع تأكيد فوري عبر المنصة.",
        },
         {
          icon: require("../../assets/images/landing-page/service3.svg"),
          title: "الشحن ",
          description:
            "توفير حلول شحن متكاملة تشمل الشحن الجوي، البحري، والبري، مع ميزات التتبع اللحظي وإدارة الشحنات من البداية إلى النهاية.",
        },

      ],
    };
  },
};
</script>

<style scoped lang="scss">
.services {
  margin-top: 3.438rem;
  margin-bottom: 5.938rem;

  .platform-title {
    color: #3064c1;
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  .platform-heading {
    color: #0f172a;
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 1.25rem;
    margin-bottom: 1.5rem;
    @media(max-width:1024px){
      line-height: 3rem;
    }
  }

  .platform-description {
    color: #1B335E;
    font-size: 1.5rem;
    line-height: 2.479rem;
    margin-bottom: 3.5rem;
  }

  .features-row {
   // padding-bottom: 4.813rem;

    .feature-card {
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 10px;
      text-align: center;
      padding: 20px;
      transition: all 0.3s ease;
      min-height: 23.063rem;

      &:hover {
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
        transform: translateY(-5px);
      }

      .feature-icon {
        margin-bottom: 1.375rem;
        margin-top: 2.125rem;
        img {
          margin: auto;
        }
      }

      .feature-title {
        font-size: 1.25rem;
        font-weight: 700;
        color: #000b33;
        line-height: 2rem;
        margin-bottom: 1.5rem;
      }

      .feature-description {
        color: #000b33;
        font-size: 1.125rem;
        line-height: 1.75rem;
        font-weight: 400;
        margin-bottom: 0rem;
      }
    }
  }
}
</style>

<template>
  <v-footer class="footer" padless>
    <v-container class="footer__container">
      <!-- CSS Grid Layout -->
      <div class="footer__grid">
        <!-- Section 1: Logo & Social Media -->
        <div class="footer__section footer__social">
          <img
            src="../../../assets/images/footerLogo.png"
            alt="Caship Logo"
            class="footer__logo"
          />
        </div>
        <!-- Section 2: Address -->
        <div class="footer__section">
          <h3 class="footer__title">
            <img
              src="../../../assets/images/address-logo.svg"
              class="footer__title--icon"
            />
            العنوان
          </h3>
          <ul class="footer__list">
            <li class="footer__list--text">الدائري الشمالي ، حي التعاون ، <br> الرياض ، المملكة العربية السعودية</li>
          </ul>
        </div>
        <!-- Section 3: Contact Us -->
        <div class="footer__section">
          <h3 class="footer__title">تواصل معنا</h3>
          <div class="d-flex align-baseline gap-30">
            <ul class="footer__list">
              <li>
                <p class="footer__list--contact">
                  <img src="../../../assets/images/footer-whats.svg" />
                  واتساب
                </p>
                <a class="footer__list--number" target="_blank" href="https://wa.me/966543849163">0543849163</a>
              </li>
            </ul>
            <ul class="footer__list">
              <li>
                <p class="footer__list--contact">
                  <img src="../../../assets/images/footer-message.svg" />
                  البريد الالكتروني
                </p>
                <a class="footer__list--number" target="_blank" href="mailto:contact@caship.sa">contact@caship.sa</a>
              </li>
            </ul>
          </div>
        </div>
        <!-- Section 5: Important Links -->
        <div class="footer__section">
          <h3 class="footer__title">روابط مهمة</h3>
          <ul class="footer__list">
            <li class="footer__list--text">
              <router-link to=""> الشروط والأحكام </router-link>
            </li>
            <li class="footer__list--text mt-2">
              <router-link :to="{ name: 'privacy-policy' }">
                سياسة الخصوصية
              </router-link>
            </li>
          </ul>
        </div>
        <!-- Section 6: Important Links -->
        <div class="footer__section">
          <h3 class="footer__title">مزيد من المعلومات</h3>
          <ul class="footer__list">
            <li class="footer__icons">
              <a href="#" class="footer__btn">
                <img src="../../../assets/images/footer-x.svg" />
              </a>
              <a href="#" class="footer__btn">
                <img src="../../../assets/images/instgram-footer.svg" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "CustomFooter",
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: #1b335e !important;
  color: #ffffff !important;
  padding-top: 3rem;
  padding-bottom: 3rem;
  &__container {
    padding-bottom: 0rem !important;
    margin: 0 auto;
  }

  &__grid {
    display: flex;
    justify-content: baseline;
    column-gap: 2rem;
    justify-content: space-between;
    @media (max-width: 1024px) {
      display: grid;
      gap: 1.5rem;
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__section {
    grid-column: span 1; /* Each section spans 2 columns */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: right;
  }

  &__title {
    font-size: 1.105rem;
    font-weight: 700;
    margin-bottom: 0.728rem;
    min-height: 1.625rem;
    &--icon {
      display: inline-block;
      width: 1.005rem;
      height: 1.206rem;
      margin-inline-end: 0.829rem;
    }
    @media (max-width: 960px) {
      min-height: unset;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    a {
      color: #ffff;
      &:hover {
        text-decoration: underline; /* Underline only on hover */
      }
    }
    &--text,
    &--number {
      color: #cacaca !important;
      font-weight: 400;
      line-height: 1.612rem;
      font-size: 1.031rem !important;
      a {
        color: #cacaca !important;
      }
    }
    &--number {
      direction: ltr;
      margin-top: 0.5rem;
      display: inline-block;
    }
    &--contact,
    &--mail {
      font-size: 1rem;
      img {
        display: inline-block;
        margin-inline-end: 0.438rem;
      }
    }
    &--mail {
      margin-top: 0.5rem;
      display: inline-block;
    }

    li {
      font-size: 0.9rem;
      p {
        margin-bottom: 0rem !important;
      }

      .footer__icon {
        margin-left: 8px;
        color: #ffffff;
      }
    }
  }

  &__logo {
    width: 7.443rem;
    height: 2.428rem;
    margin-bottom: 1rem;
  }

  &__icons {
    display: flex;
    gap: 1rem;
    a {
      color: #ffffff;
    }
    .footer__btn {
      margin-top: 0.438rem;
      img {
        width: 2.831rem;
        height: 2.831rem;
      }
    }
  }
  @media (max-width: 960px) {
    height: unset !important;
  }
}
</style>

<template>
  <div class="latest-articles">
    <v-container class="py-0">
      <v-row>
        <v-col cols="12">
          <h2 class="section-title">أحدث المقالات والأخبار</h2>
        </v-col>
      </v-row>
      <v-row class="articles">
        <v-col
          cols="12"
          md="4"
          v-for="(article, index) in articles.slice(0, 3)"
          :key="index"
        >
          <v-card class="article-card">
            <img
              class="article-img"
              :src="article.image.original_url"
              :alt="article.image.alt"
            />
            <v-card-text>
              <h3 class="article-title">{{ article.title }}</h3>
              <p class="article-description">{{ article.body }}</p>
            </v-card-text>
            <v-card-actions>
              <a :href="article.url" target="_blank" class="read-more-btn" text>
                اقرأ المزيد
                <v-icon right color="#C53C1C">mdi-chevron-left</v-icon>
              </a>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      articles: [], // Empty by default
    };
  },
  methods: {
    async fetchArticles() {
      try {
        const response = await axios.get("https://blog.caship.sa/api/articles"); // Replace with your API URL
        this.articles = response.data; // Update the articles with API response
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    },
  },
  created() {
    this.fetchArticles(); // Fetch articles when the component is created
  },
};
</script>

<style lang="scss">
.latest-articles {
  margin-top: 4rem;
  margin-bottom: 2.25rem;
  .section-title {
    font-size: 2.25rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: #000000;
  }

  .articles {
    .article-card {
      box-shadow: unset !important;
      border-radius: 10px;
      overflow: hidden;
      .article-img {
        width: 100%;
        max-height: 215px;
        object-fit: cover;
        border-radius: 24px !important;
      }
      .article-title {
        font-size: 1.25rem;
        font-weight: 700;
        margin-top: 2.029rem;
        margin-bottom: 1rem;
        line-height: 1.395rem;
        color: #000000;
        display: -webkit-box; /* Create a flexible box */
        -webkit-box-orient: vertical; /* Set the orientation to vertical */
        overflow: hidden; /* Hide overflowed content */
        -webkit-line-clamp: 1;
      }

      .article-description {
        font-size: 1.063rem;
        color: #4e4e4e;
        font-weight: 400;
        margin-bottom: 0rem;
        display: -webkit-box; /* Create a flexible box */
        -webkit-box-orient: vertical; /* Set the orientation to vertical */
        overflow: hidden; /* Hide overflowed content */
        -webkit-line-clamp: 2;
      }

      .read-more-btn {
        color: #c53c1c;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.116rem;
        padding: 0px;
        margin-top: 1.5rem;
        .v-btn__content {
          i {
            margin-inline-end: 0.94rem;
          }
        }
      }
    }
  }
}
.theme--light.v-card > .v-card__subtitle,
.theme--light.v-card > .v-card__text {
  padding: 0px !important;
}
.v-card__actions {
  padding: 0px !important;
}
</style>

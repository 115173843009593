<template>
  <div class="questions">
    <v-container>
      <div class="questions__content">
        <h3 class="questions__content__title">
          إجابات على أهم الأسئلة الشائعة عن كاشيب
        </h3>
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel v-for="panel in panels" :key="panel.id">
            <v-expansion-panel-header
              hide-actions
              class="questions__content__details"
            >
              <template v-slot:default="{ open }">
                <div class="questions__content--header">
                  {{ panel.title }}
                  <v-icon>
                    <template v-if="open">mdi-minus</template>
                    <template v-else>mdi-plus</template>
                  </v-icon>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="questions__content--text">
              {{ panel.content }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      panel: [0], // To track opened/closed panels
      panels: [
        {
          id: 1,
          title: "ما هي الخدمات التي تقدمونها في مجال الشحن الدولي؟",
          content:
            "يمكنك تتبع شحنتك باستخدام رقم التتبع المقدم من قبلنا عبر موقعنا الإلكتروني أو من خلال التواصل مع فريق خدمة العملاء.",
        },
        {
          id: 2,
          title: "كيف يمكنني تتبع شحنتي؟",
          content:
            "يمكنك تتبع شحنتك باستخدام رقم التتبع المقدم من قبلنا عبر موقعنا الإلكتروني أو من خلال التواصل مع فريق خدمة العملاء.",
        },
        {
          id: 3,
          title: "ما هي المستندات المطلوبة لتخليص الشحنات من الجمارك؟",
          content:
            "يمكنك تتبع شحنتك باستخدام رقم التتبع المقدم من قبلنا عبر موقعنا الإلكتروني أو من خلال التواصل مع فريق خدمة العملاء.",
        },
        {
          id: 4,
          title: "هل تقدمون خدمات التخزين؟",
          content:
            "يمكنك تتبع شحنتك باستخدام رقم التتبع المقدم من قبلنا عبر موقعنا الإلكتروني أو من خلال التواصل مع فريق خدمة العملاء.",
        },
        {
          id: 5,
          title: "كيف يمكنني الحصول على عرض سعر لشحنتي؟",
          content:
            "يمكنك تتبع شحنتك باستخدام رقم التتبع المقدم من قبلنا عبر موقعنا الإلكتروني أو من خلال التواصل مع فريق خدمة العملاء.",
        },
        {
          id: 6,
          title: "ما هي الدول التي تقومون بالشحن إليها؟",
          content:
            "يمكنك تتبع شحنتك باستخدام رقم التتبع المقدم من قبلنا عبر موقعنا الإلكتروني أو من خلال التواصل مع فريق خدمة العملاء.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.questions {
  background-color: #f4f4f4;
  margin-bottom: 4rem;
  &__content {
    &__title {
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 2.79rem;
      color: #000000;
      margin-bottom: 3.152rem;
      padding-top: 3.438rem;
      display: block;
      @media (max-width: 1024px) {
        line-height: 3.5rem;
      }
      &__qoutes {
        position: absolute;
        top: 0;
        left: -50px;
      }
    }
    &--header {
      font-size: 1rem;
      font-weight: 700;
      margin-bottom: 0.281rem;
      @media (max-width: 1024px) {
        line-height: 1.5rem;
      }
    }
    &--text {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 0.976rem;
      //padding-bottom: 0.688rem;
      @media (max-width: 1024px) {
        line-height: 1.5rem;
      }
    }
  }
  .v-expansion-panel--active {
    background: #b0a9a7;
  }
  .theme--light.v-expansion-panels .v-expansion-panel {
    background-color: transparent;
  }
}
::v-deep .v-expansion-panel-content__wrap {
  padding-bottom: 0.688rem !important;
}
</style>

<template>
  <div>
    <v-container class="mobile-nav d-md-none py-4">
      <!-- Navigation Drawer -->
      <router-link class="navbar__logo" :to="{ name: 'home' }">
        <img width="90px" src="@/assets/images/logo-blue.svg" />
      </router-link>
      <v-app-bar-nav-icon @click="sidebar = !sidebar"></v-app-bar-nav-icon>
      <v-navigation-drawer v-model="sidebar" hide-overlay app width="100%">
        <div class="d-flex align-center justify-end pa-4">
          <img
            @click="sidebar = false"
            class="cursor-pointer"
            src="@/assets/images/close.svg"
            alt="close-btn"
          />
        </div>
        <v-list shaped class="px-0">
          <template v-if="!isLoggedIn">
            <v-list-item>
              <router-link :to="{ name: 'home' }" class="d-flex align-center">
                <v-list-item-icon>
                  <v-icon>mdi-home-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>الرئيسية</v-list-item-title>
                </v-list-item-content>
              </router-link>
            </v-list-item>
            <v-list-item>
              <a
                href="#digital-solutions"
                class="d-flex align-center"
                :class="{ active: activeSection === 'digital-solutions' }"
              >
                <v-list-item-icon>
                  <v-icon>mdi-laptop</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>الحلول الرقمية</v-list-item-title>
                </v-list-item-content>
              </a>
            </v-list-item>
            <v-list-item>
              <a
                href="#services"
                class="d-flex align-center"
                :class="{ active: activeSection === 'services' }"
              >
                <v-list-item-icon>
                  <v-icon>mdi-cog-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>الخدمات</v-list-item-title>
                </v-list-item-content>
              </a>
            </v-list-item>
            <v-list-item>
              <a
                href="#faq"
                class="d-flex align-center"
                :class="{ active: activeSection === 'faq' }"
              >
                <v-list-item-icon>
                  <v-icon>mdi-help-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>الأسئلة الشائعة</v-list-item-title>
                </v-list-item-content>
              </a>
            </v-list-item>
            <v-list-item>
              <a
                href="https://blog.caship.sa"
                target="_blank"
                class="d-flex align-center"
                :class="{ active: activeSection === 'blog' }"
              >
                <v-list-item-icon>
                  <v-icon>mdi-notebook-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>المدونة</v-list-item-title>
                </v-list-item-content>
              </a>
            </v-list-item>
            <v-list-item>
              <a
                href="#contact"
                class="d-flex align-center"
                :class="{ active: activeSection === 'contact' }"
              >
                <v-list-item-icon>
                  <v-icon>mdi-email-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>تواصل معنا</v-list-item-title>
                </v-list-item-content>
              </a>
            </v-list-item>
            <v-list-item>
              <router-link
                :to="{ name: 'register' }"
                class="d-flex align-center"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account-plus-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>انضم إلينا</v-list-item-title>
                </v-list-item-content>
              </router-link>
            </v-list-item>
            <v-list-item>
              <v-btn
                @click="$router.push({ name: 'login' })"
                dark
                block
                color="#c53c1c"
                class="rounded-lg elevation-0"
              >
                <span class="text-base">تسجيل الدخول</span>
              </v-btn>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-item>
              <router-link to="{ name: 'home' }" class="d-flex align-center">
                <v-list-item-icon>
                  <v-icon>mdi-home-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>الرئيسية</v-list-item-title>
                </v-list-item-content>
              </router-link>
            </v-list-item>
            <v-list-item>
              <router-link
                :to="{ name: 'profile' }"
                class="d-flex align-center"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>حسابي</v-list-item-title>
                </v-list-item-content>
              </router-link>
            </v-list-item>
            <v-list-item>
              <a
                href="#digital-solutions"
                class="d-flex align-center"
                :class="{ active: activeSection === 'digital-solutions' }"
              >
                <v-list-item-icon>
                  <v-icon>mdi-laptop</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>الحلول الرقمية</v-list-item-title>
                </v-list-item-content>
              </a>
            </v-list-item>
            <v-list-item>
              <a
                href="#services"
                class="d-flex align-center"
                :class="{ active: activeSection === 'services' }"
              >
                <v-list-item-icon>
                  <v-icon>mdi-cog-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>الخدمات</v-list-item-title>
                </v-list-item-content>
              </a>
            </v-list-item>
            <v-list-item>
              <router-link
                :to="{ name: 'notifications' }"
                class="d-flex align-center"
              >
                <v-list-item-icon>
                  <v-icon>mdi-bell-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>الإشعارات</v-list-item-title>
                </v-list-item-content>
              </router-link>
            </v-list-item>
            <v-list-item>
              <router-link :to="{ name: 'logout' }" class="d-flex align-center">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>تسجيل خروج</v-list-item-title>
                </v-list-item-content>
              </router-link>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>
    </v-container>
    <div class="nav-bar d-md-block d-none">
      <v-container class="py-6">
        <v-toolbar dense flat>
          <div
            v-if="!isLoggedIn"
            class="navbar d-flex align-center h-100 w-100 mx-auto"
          >
            <router-link class="navbar__logo ml-md-9" :to="{ name: 'home' }">
              <img width="90px" src="@/assets/images/logo-blue.svg" />
            </router-link>
            <a
              href="#digital-solutions"
              class="navbar__link d-flex align-center h-100 black--text text-decoration-none ml-9"
              :class="{ active: activeSection === 'digital-solutions' }"
            >
              الحلول الرقمية
            </a>
            <a
              href="#services"
              class="navbar__link d-flex align-center h-100 black--text text-decoration-none ml-9"
              :class="{ active: activeSection === 'services' }"
            >
              الخدمات
            </a>
            <a
              href="#faq"
              class="navbar__link d-flex align-center h-100 black--text text-decoration-none ml-9"
              :class="{ active: activeSection === 'faq' }"
            >
              الاسئلة الشائعة
            </a>
            <a
              href="https://blog.caship.sa"
              target="_blank"
              class="navbar__link d-flex align-center h-100 black--text text-decoration-none ml-9"
            >
              المدونه
            </a>
            <a
              href="#contact"
              class="navbar__link d-flex align-center h-100 black--text text-decoration-none ml-9"
              :class="{ active: activeSection === 'contact' }"
            >
              تواصل معنا
            </a>
            <v-spacer></v-spacer>
            <router-link
              exact
              :to="{ name: 'register' }"
              class="navbar__link d-flex align-center h-100 black--text text-decoration-none ml-9"
            >
              انضم الينـا
            </router-link>
            <v-btn
              @click="$router.push({ name: 'login' })"
              dark
              color="#c53c1c"
              class="rounded-lg elevation-0"
            >
              <span class="text-base"> تسجيل الدخول</span>
            </v-btn>
          </div>
          <div
            v-if="isLoggedIn"
            class="navbar d-flex align-center justify-between w-100 mx-auto register-nav"
          >
            <div class="d-flex align-center gap-22">
              <div class="navbar__logo">
                <img width="90px" src="@/assets/images/logo-blue.svg" />
              </div>

              <img src="@/assets/images/vertical-seperator.svg" />
              <div class="d-flex align-center gap-12">
                <img
                  width="22px"
                  class="mb-1"
                  src="@/assets/images/goodbye.svg"
                />
                <span class="text-sm font-bold"
                  >أهلاً {{ $store.getters.userInfo.fullName }}</span
                >
              </div>
              <img width="1px" src="@/assets/images/vertical-seperator.svg" />
              <div class="d-flex align-center gap-14 cursor-pointer">
                <img src="@/assets/images/search.svg" />
                <span class="lightgray--text font-normal text-xs"
                  >ابحـث في كاشيـب</span
                >
              </div>
            </div>
            <div class="d-flex align-center gap-30">
              <div class="d-flex align-center gap-8 cursor-pointer">
                <img src="@/assets/images/globe.svg" />
                <span class="black--text font-normal text-xs">ع</span>
              </div>
              <v-badge
                class="cursor-pointer"
                :content="1"
                :value="1"
                color="error"
                overlap
              >
                <img src="@/assets/images/bell.svg" />
              </v-badge>
              <img
                class="rounded-lg"
                src="@/assets/images/profile-picture.svg"
              />
            </div>
          </div>
        </v-toolbar>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeSection: "",
      sidebar: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  mounted() {
    const sections = [
      "digital-solutions",
      "services",
      "faq",
      "blog",
      "contact",
    ];
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) this.activeSection = entry.target.id;
      });
    });

    sections.forEach((section) => {
      const el = document.querySelector(`#${section}`);
      if (el) observer.observe(el);
    });
  },
};
</script>

<style lang="scss" scoped>
.mobile-nav {
  background-color: #f1f1f1;
  display: flex;
  justify-content: space-between;
}
.navbar {
  &__link {
    font-size: 1rem;
    font-weight: 400;
    position: relative;

    &:not(:last-child) {
      margin-left: 9px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: #c53c1c;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    &:hover,
    &.active {
      color: $primary;
      &::after {
        transform: scaleX(1);
        transform-origin: bottom right;
      }
      .navbar__account__icon {
        color: inherit !important;
        transform: rotateX(180deg);
      }
    }
    &.router-link-active {
      color: #c53c1c !important;
      &::after {
        transform: scaleX(1);
      }
    }
  }
}
.nav-bar {
  background-color: #f1f1f1;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  ::v-deep .v-toolbar.v-sheet,
  ::v-deep .v-toolbar__content {
    height: unset !important;
  }
}
::v-deep .v-toolbar__content,
.v-toolbar__extension {
  padding: 0px;
}
::v-deep .v-toolbar.v-sheet {
  background-color: #f1f1f1 !important;
}
.register-nav {
  padding: 16px;
}
</style>
